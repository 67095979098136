.btn {

    &-link {
        color: $c-blue;
    }

    &-primary {
        color: $c-white;
        background-color: $c-blue;
        border-color: $c-blue;
    }

    &-warning {
        color: $c-black;
        background-color: $c-yellow;
        border-color: $c-yellow;
    }

    &-outline-primary {
        color: $c-blue;
        border-color: $c-blue;

        &:hover {
            background-color: $c-blue;
        }
    }
}

.page-item.active .page-link {
    border-color: $c-blue;
    background-color: $c-blue;
}

.page-link {
    color: $c-blue;
}

.alert {

    p, ul {
        margin-bottom: 0;
    }

    &-danger {
        color: $c-white;
        background-color: $c-red;
        border-color: $c-red;
    }

    &-success {
        color: $c-white;
        background-color: $c-green;
        border-color: $c-green;
    }

    &-warning {
        color: $c-black;
        background-color: $c-yellow;
        border-color:$c-yellow;
    }
}
