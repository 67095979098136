@import "../../node_modules/reset-css/sass/reset";
@import "../../node_modules/breakpoint-sass/stylesheets/breakpoint";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/select2/src/scss/core";

@import url('https://fonts.googleapis.com/css?family=Alegreya:400,400i,500,500i,700,700i,800,800i,900,900i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');

@import "global/variables";
@import "global/general";
@import "global/header";
@import "global/main";
@import "global/footer";
@import "global/rebootstrap";

@import "components/cards";
@import "components/table";
@import "components/form";
@import "components/files";
@import "components/filter";
