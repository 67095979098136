.card {

    &-header {
        font-family: $f-main;
        font-weight: 600;
        letter-spacing: 0.125rem;
        font-size: 1.125rem;
        text-align: center;
        text-transform: uppercase;
    }
}
