body {
    font-family: $f-text;
    font-size: 0.95rem;
    color: $c-dark-grey;
    background-color: $c-background;

    h1, h2, h3, h4, h5, h6 {
        font-family: $f-main;
        font-size: 1.125rem;
        font-weight: 800;
        margin-bottom: 0;
    }
}
