table.table {

    tr {

        &.table-dropped {

            td {
                text-decoration: line-through;
                color: $c-medium-grey;
                font-style: italic;
            }
        }
    }

    th {

        a {
            color: $c-white;
            margin-right: 0.375rem;
        }
    }

    td {
        vertical-align: middle;
    }

    .btn {

        &-link {
            color: $c-dark-grey;
        }
    }

    &-striped {
        tbody tr:nth-of-type(#{$table-striped-order}) {
            background-color: $c-white;
        }

        tbody tr:nth-of-type(even) {
            background-color: $c-light-grey;
        }
    }

    &-hover {
        tbody tr {
            @include hover {
                color: $table-hover-color;
                background-color: $table-hover-bg;
            }
        }
    }

    &.table--show-data {

        th {
            width: 13.5rem;
        }
    }
}
