footer#footer {
    margin: 0 0.5rem 1rem;
    padding: 3rem 1rem;
    border-top: 1px dashed $c-magenta;
    font-size: 0.8rem;
    text-align: center;

    @include breakpoint($bp-s) {
        margin: 0 1.5rem 1rem;
    }

    @include breakpoint($bp-m) {
        margin: 0 4.5rem 1rem;
    }

    @include breakpoint($bp-xl) {
        margin: 0 6rem 1em;
    }

    img {
        width: 110px;
        height: auto;
        display: block;
        margin: 0 auto 1em;
    }

    a {
        color: $c-dark-grey;
        text-decoration: underline;
    }
}
