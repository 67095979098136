// ---- typography ----

// typefaces
$f-main: 'Alegreya', serif;
$f-text: 'Roboto', sans-serif;

$tw-black: 900;

// ---- breakpoints ----

$bp-xxs:20em;		/* 320px */
$bp-xs:35.5em;		/* 568px */
$bp-s:37.5em; 		/* 600px */
$bp-sm:48em; 		/* 768px */
$bp-m:50em;			/* 800px */
$bp-ml:64em;		/* 1024px */
$bp-l:80em;			/* 1280px */
$bp-xl:115em;		/* 1920px */
$bp-xxl:160em;		/* 2560px */


// ---- colors ----

$c-black: rgb(0,0,0);
$c-white: rgb(255,255,255);
$c-white-trans: rgba(255,255,255, .8);
$c-magenta: #e91e4f;
$c-blue: #3f6de6;
$c-orange: #f28130;
$c-green: #78ae4a;
$c-yellow: #f3b700;
$c-red: #f54029;
$c-dark-grey: #2a2d34;
$c-medium-grey: #888888;
$c-light-grey: #eeeeee;
$c-background: #f4f3ef;

$c-error:#f72f2f;
$c-error--light:lighten($c-error, 32);
$c-error--lightest:lighten($c-error, 36);

// ---- z-index ----

$z-overlay:18;
$z-overlay-background:15;
$z-nav-plus:12;
$z-nav:9;
$z-regular-plus:6;
$z-regular:3;
$z-neutral:0;
$z-background:-1;



//---- misc ----

$transdur: 250ms;
