.form-dropzone {
    background: lighten($c-blue, 40%);
    border: 2px dotted lighten($c-blue, 20%);
    margin-bottom: 2rem;
    min-height: 5rem;
    padding: 1rem;

    &:hover {
        cursor: pointer;
    }

    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;

    .dz-message {
        grid-column: 1 / -1;
        text-align: center;
        color: $c-medium-grey;
    }

    .dz-preview {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        text-align: center;
        font-size: 0.85rem;
        color: $c-blue;

        .dz-image {
            grid-column: 1 / -1;
            margin-bottom: 0.5rem;

            img {
                width: 100%;
                height: auto;
            }
        }

        .dz-details {
            grid-column: 1 / -1;
        }

        .dz-success-mark, .dz-error-mark {
            svg {
                width: 65%;
                height: auto;
            }
        }

        .dz-error-message {
            grid-column: 1 / -1;
            span {
                display: block;
                color: white;
                background-color: #f54029;
                border-color: #f54029;
                border-radius: 0.25rem;
            }
        }

        &.dz-success {
            .dz-success-mark {
                svg {
                    background: green;
                    border-radius: 2rem;
                }
            }
        }
    }
}
