.filter {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    grid-column-gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;

    &-item {

        @include breakpoint($bp-s) {
            display: grid;
            grid-template-columns: auto minmax(0, 1fr);
            grid-column-gap: 1rem;
            align-items: center;
        }
    }
}
