section#main {
    margin: 0 0.5rem 3em;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);

    @include breakpoint($bp-s) {
        margin: 0 1.5rem 3em;
    }

    @include breakpoint($bp-m) {
        margin: 0 4.5rem 3em;
    }

    @include breakpoint($bp-xl) {
        margin: 0 6rem 3em;
    }
}
