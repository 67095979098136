header#header {
    margin: 0 0.5rem 1rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);

    @include breakpoint($bp-s) {
        margin: 0 1.5rem 1rem;
    }

    @include breakpoint($bp-m) {
        margin: 0 4.5rem 1rem;
    }

    @include breakpoint($bp-xl) {
        margin: 0 6rem 2rem;
    }

    #header-top {
        grid-column: 1 / -1;
        padding: 0.5rem 1rem;

        display: grid;
        grid-template-columns: auto repeat(1, minmax(0,1fr)) 92px;
        grid-column-gap: 2rem;
        grid-template-rows: 28px;

        @include breakpoint($bp-xl) {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }

        h1 {
            color: $c-magenta;
            text-transform: uppercase;
            align-self: center;
            letter-spacing: 0.125rem;
            line-height: 0.8rem;
            font-size: 1rem;

            img {
                width: 1rem;
                height: auto;
                position: relative;
                top: -0.2rem;
            }
        }

        nav.header-top-navigation {
            align-self: center;

            ul {
                margin-bottom: 0;
                line-height: 0.8rem;
                display: flex;
                flex-direction: row;
                align-items: center;

                > li {
                    display: inline-block;
                    margin-right: 1.5rem;
                    line-height: 0.8rem;

                    &.active a {
                        color: $c-blue;
                    }

                    a {
                        text-decoration: none;
                        line-height: 0.8rem;
                        color: $c-medium-grey;
                        text-transform: uppercase;
                        letter-spacing: 0.125rem;
                        font-size: 0.8rem;
                        font-weight: 500;

                        &:hover {
                            color: $c-dark-grey;
                            transition: ease-in 400ms;
                        }

                        &.btn-circle {
                            color: $c-white;

                            &:hover {
                                color: $c-white;
                            }
                        }
                    }
                }
            }
        }

        a {

            &.btn-circle {
                border-radius: 50%;
                width: 28px;
                height: 28px;
                padding: 8px 0 0 1px;
                color: $c-white;

                &:hover {
                    border-color: $c-magenta;
                    background-color: $c-magenta;
                    color: $c-white;
                    transition: ease-in 250ms;
                }
            }
        }

        a.header-info {
            padding-top: 2px;
        }

        a.header-signout {
            grid-column-end: -1;
            display: inline-block;
            width: 28px;
            height: 28px;
            background-color: $c-magenta;
            border-radius: 50%;
            text-align: center;
            color: $c-white;
            padding-top: 3px;
            padding-left: 2px;
            align-self: center;

            &:hover {
                background-color: $c-blue;
                color: $c-white;
                transition: ease-in 250ms;
            }
        }
    }

    #header-bottom {
        grid-column: 1 / -1;
        padding: 0.75rem 0.75rem;
        background: $c-magenta;
        color: $c-white;

        @include breakpoint($bp-s) {
            border-radius: 2rem;
        }

        h1 {
            display: inline-block;
            text-transform: uppercase;
            letter-spacing: 0.125rem;
            font-weight: 600;
        }

        div.dropdown {
            float: right;

            .btn {
                border-radius: 1.5rem;
            }
        }

        nav.header-bottom-navigation {
            display: inline-block;

            ul {
                display: inline-block;
                margin-bottom: 0;

                > li {
                    display: inline-block;
                    margin-right: 0.5rem;

                    a {
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        background-color: $c-white;
                        border-radius: 50%;
                        text-align: center;
                        color: $c-magenta;
                        padding-top: 9px;

                        &:hover {
                            background-color: $c-blue;
                            color: $c-white;
                            transition: ease-in 250ms;
                        }
                    }
                }
            }
        }
    }
}
