.form-box {
    display: grid;
    grid-template-columns: repeat(1, minmax(0,1fr));
    grid-row-gap: 2rem;
    grid-auto-flow: dense;

    @include breakpoint($bp-ml) {
        grid-template-columns: repeat(2, minmax(0,1fr));
        grid-column-gap: 2rem;
    }

    &.form-box-3col {
        @include breakpoint($bp-l) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    &-footer {
        grid-column: 1 / -1;
    }

    h2 {
        color: $c-blue;
        font-weight: 400;
        margin-bottom: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.125rem;
        padding-bottom: 1rem;
        border-bottom: dashed 1px $c-blue;
    }

    .select2-container--default .select2-selection--single {
        padding: 0.375rem;
        height: auto;
        border-color: #ced4da;

        .select2-selection__arrow b {
            top: 72%;
        }
    }

    .form-group {

        .form-group-optionvalues {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            grid-column-gap: 0.75rem;
        }
    }
}
